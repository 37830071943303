const Footer = () => {
    return (
        <>
            <footer>
                <div className="container">
                    <div className="grid-12">
                        <div className="footer">
                            <p>Birrib © 2023 - Todos os direitos reservados</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;