import { useEffect } from "react";

import gsap from "gsap";

const Animation = () => {

    useEffect(() => {

          const tl = gsap.timeline();
          tl.fromTo("#title", {y: 50, opacity: 0}, {y: 0, opacity: 1, duration: 0.8}, "title");       
          tl.fromTo(".card-container", {y: 50, opacity: 0}, {y: 0, opacity: 1, duration: 0.8}, "title-=0.5");
   
        
    }, []);

}

export default Animation;

