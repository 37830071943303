import logoPreloader from "assets/svg/preloader.svg";

import { useEffect } from "react";

import gsap from "gsap";

function Preloader() {

  useEffect(() => {
            const tl = gsap.timeline({defaults: {duration: 1}});       

           function loaded() {
                tl.to(".counter", {onComplete: uiTitle, innerText: 100, snap: {innerText:5}, duration: 3})
                tl.to(".overlay", {display: "none", opacity: 0, duration: 1.2});
        
                function uiTitle() {
                  tl.fromTo(".logo", {y: 20, opacity: 0}, {y: 0, opacity: 1, duration: 0.8}, "-=1.5");
                  tl.fromTo("li", {y: 20, opacity: 0}, {y: 0, opacity: 1, duration: 0.8}, "-=1.5");
                  tl.fromTo("#title", {y: 50, opacity: 0}, {y: 0, opacity: 1, duration: 1}, "-=1.2");
                  tl.fromTo(".card", {y: 50, opacity: 0}, {y: 0, opacity: 1, duration: 0.8}, "-=1");
                }
            }  

            loaded();

        }, []);

    return (
        <>  
           
          <div className="overlay">
            <span className="counter">0</span>
            <img className="birrib_logo" src={logoPreloader} alt="Logo Preloader" />
        </div>
        <div className="big-noise"></div>
        </>
    );
  }
  
  export default Preloader;