//HEADER & FOOTER
import Header from "page/Header";
import Footer from "page/Footer";


//COMPONENTS
import Desc from "./Desc";
import Card from "./Card";

//API
import api from "services/api";

//HOOKS
import { useState, useEffect } from "react";


const Home = () => {

    //VARIÁVEIS DE ESTADO
    const [post, setPost] = useState([]);    
    const [desc, setDesc] = useState([]);

    //EXECUTE QUANDO O COMPONENTE MONTAR
    useEffect(() => {
        api.get('/project?status=true')
        .then((response) => {
            setPost(response.data)
        });

        api.get('/desc')
        .then((response) => {
            setDesc(response.data);
            
        })
        
    }, [])

    return (
        <>
            <Header/>
            <section className="hero hero_home">
                {
                    desc.map((items) => {
                        return <Desc key={items.id} content={items}/>
                    })
                }
                    <div className="container card-container">
                        {
                            post.map((item) => {
                                return <Card key={item.id} content={item} />
                            })
                        }
                    </div>
            </section>
            <Footer/>
        </>
    );
}

export default Home;