//HEADER & FOOTER
import Header from "page/Header";
import Footer from "page/Footer";


const Contact = () => {
    return (
        <>
            <Header />

                <h1>Contact.js</h1>

            <Footer />
        </>
    );
}

export default Contact;