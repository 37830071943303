//HOOKS
import Paths from "paths";
import Preloader from "page/Preloader";

//HOOKS
//import { useState, useEffect } from "react";


function App() {
  
  return (
      <>  
        <Paths />
        <Preloader />
      </>
  );
}

export default App;
