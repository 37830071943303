import Animation from "js/animation.js";

const Desc = ( {content} ) => {
    Animation();
    return (
        <>
            <div className="container port-container">
                <div className="grid-12 port-title">
                    <h1 id="title">Birrib® is the Animation work of Johnny Oliveira,<br/>
                    a Motion Designer based in São Paulo, Brazil.</h1>
                </div>
            </div>
        </>
    );
}

export default Desc;