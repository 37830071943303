import arrowLink from "assets/svg/arrowLink.svg";
import btnPlay from "assets/svg/play.svg";

//LINKS
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";

import Animation from "js/animation.js";


const Card = ( {content} ) => {
    Animation();
    
    return (
        <>
            <div className="grid-card-4 card">
                <div className="card-thumbHover"></div>
                <div className="thumb hidden video">
                    <Link to={"/project/" + content.id}>
                        <ProgressiveImage src={content.imageUrl} placeholder={content.lazyUrl}>
                            {src => <img src={src} alt="an image"/>}
                        </ProgressiveImage>
                    </Link>
                    <div className="btn-external-link">
                        <img src={arrowLink} alt="thumbnail" />
                    </div>
                </div>
                <div className="card-info">
                    <div className="play-min">
                        <span className="play-icon"><img src={btnPlay} alt="Play icon" /></span>
                        <p className="min-icon">{content.timer}</p>
                    </div>
                    <div className="card-details">
                        <Link to={"/project/" + content.id}>
                            <h2 className="card-title">{content.title}</h2>
                        </Link>
                        <h5 className="card-subtitle">{content.subtitle}</h5>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Card;

//<img src={content.imageUrl} alt="thumbnail" />