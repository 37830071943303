//HEADER & FOOTER
import Header from "page/Header";
import Footer from "page/Footer";

import btnPlay from "assets/svg/play.svg";

//usePARMS
import { useParams } from "react-router-dom";

//HOOKS
import { useState, useEffect } from "react";

//API
import api from "services/api";

//LAZY IMAGE
import ProgressiveImage from "react-progressive-image";

const Post = () => {

    const { idPost } = useParams();

     //VARIÁVEIS DE ESTADO
     const [project, setPost] = useState([]);    
 
     //EXECUTE QUANDO O COMPONENTE MONTAR
     useEffect(() => {
         api.get('/project/' + idPost)
         .then((response) => {
             setPost(response.data)
         });
     }, []);

     useEffect(() => {
        const fullImg = document.querySelector('.full-img')
        const smallImg = document.querySelectorAll('.gallery img');

        const modal = document.querySelector('.modal');

        smallImg.forEach(function(img) {
            img.addEventListener('click', function(){
                modal.classList.add('open');
                fullImg.classList.add('open');
                
                //changin' the images dynamically
                const originalQuality = img.getAttribute('src')
                fullImg.src = originalQuality;
            });
        
            modal.addEventListener('click', function(e){
            if(e.target.classList.contains('modal')){
                modal.classList.remove('open');
                fullImg.classList.remove('open');
            }
            });
        });
     });

    return (
        <>
            <Header />
                <section className="hero">
                    <div className="container post-container">
                        <div className="grid-8">
                           <div className="video-player">
                                <iframe title={project.title} className="video" src={project.videoUrl} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" data-ready="true"></iframe>
                           </div>
                        </div>
                        <div className="grid-4 post-info">
                                <div className="post-content">
                                    <div className="play-min">
                                        <span className="play-icon"><img src={btnPlay} alt="Play icon" /></span>
                                        <p className="min-icon">1:00</p>
                                    </div>
                                    <div className="post-details">
                                        <h1 className="post-title">{project.title}</h1>
                                        <h2 className="post-subtitle">{project.subtitle}</h2>
                                    </div>
                                    <div className="post-description">
                                        <p className="text-work">
                                            {project.content}
                                        </p>
                                    </div>
                                    <div className="gallery">
                                        <div className="img-container" style={{display: project.frame01}}>
                                            <ProgressiveImage src={project.frame01} placeholder={project.lazyFrame01}>
                                                {src => <img src={src} alt="Frame"/>}
                                            </ProgressiveImage>
                                        </div>
                                        <div className="img-container" style={{display: project.frame02}}>
                                            <ProgressiveImage src={project.frame02} placeholder={project.lazyFrame02}>
                                                {src => <img src={src} alt="Frame"/>}
                                            </ProgressiveImage>
                                        </div>
                                        <div className="img-container" style={{display: project.frame03}}>
                                            <ProgressiveImage src={project.frame03} placeholder={project.lazyFrame03}>
                                                {src => <img src={src} alt="Frame"/>}
                                            </ProgressiveImage>
                                        </div>
                                        <div className="img-container" style={{display: project.frame04}}>
                                            <ProgressiveImage src={project.frame04} placeholder={project.lazyFrame04}>
                                                {src => <img src={src} alt="Frame"/>}
                                            </ProgressiveImage>
                                        </div>
                                    </div>
                                    <div className="modal">
                                        <img className="full-img" src="https://raw.githubusercontent.com/birribstudio/api/main/frame-01.png" alt="full-img"/>
                                    </div>
                                    <div className="post-role">
                                        <h4>Função: <span>{project.role}</span></h4>
                                    </div>
                                </div>
                        </div>
                    </div>
                </section>

            <Footer />
        </>
    );
}

export default Post;


/* <div className="post-gallery">
<div className="gallery_img-container"><img src={post.frame01} alt="Frame" /></div>
<div className="gallery_img-container"><img src={post.frame02} alt="Frame" /></div>
<div className="gallery_img-container"><img src={post.frame03} alt="Frame" /></div>
<div className="gallery_img-container"><img src={post.frame04} alt="Frame" /></div>
</div>
<div className="modal">
<img className="modal__full-img" src="https://raw.githubusercontent.com/birribstudio/api/main/frame-01.png" alt="full-img"/>
</div> */